"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.App = void 0;
const asserts_1 = require("external/dev_april_corgi~/js/common/asserts");
const corgi = __importStar(require("external/dev_april_corgi~/js/corgi"));
const ssr_aware_1 = require("external/dev_april_corgi~/js/server/ssr_aware");
const boundary_detail_element_1 = require("./boundary_detail_element");
const go_to_trail_element_1 = require("./go_to_trail_element");
const route_controller_1 = require("./route_controller");
const search_results_overview_element_1 = require("./search_results_overview_element");
const trail_detail_element_1 = require("./trail_detail_element");
require("./app.css");
// TODO: assert little endian
function App(props, state, updateState) {
    if (!state) {
        state = route_controller_1.RouteController.getInitialState();
    }
    let route;
    if (state.active.kind === 'boundary_detail') {
        route = corgi.createVirtualElement(boundary_detail_element_1.BoundaryDetailElement, { boundaryId: state.active.id, parameters: state.parameters });
    }
    else if (state.active.kind === 'go_to_trail') {
        route = corgi.createVirtualElement(go_to_trail_element_1.GoToTrailElement, { trailId: state.active.id, parameters: state.parameters });
    }
    else if (state.active.kind === 'search_results') {
        route = corgi.createVirtualElement(search_results_overview_element_1.SearchResultsOverviewElement, { parameters: state.parameters });
    }
    else if (state.active.kind === 'trail_detail') {
        route = corgi.createVirtualElement(trail_detail_element_1.TrailDetailElement, { trailId: state.active.trail, parameters: state.parameters });
    }
    else {
        (0, asserts_1.checkExhaustive)(state.active);
    }
    return corgi.createVirtualElement(corgi.Fragment, null,
        corgi.createVirtualElement("div", { js: corgi.bind({
                controller: route_controller_1.RouteController,
                events: {
                    render: 'wakeup',
                },
                state: [state, updateState],
            }), className: "h-full" }, route));
}
exports.App = App;
if (!(0, ssr_aware_1.isServerSide)()) {
    corgi.hydrateElement((0, asserts_1.checkExists)(document.getElementById('root')), corgi.createVirtualElement(App, null));
}
