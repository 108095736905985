"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WayCategory = exports.RelationCategory = exports.PointCategory = exports.aDescendsB = exports.ENUM_SIZE = void 0;
exports.ENUM_SIZE = 64;
function aDescendsB(a, b) {
    let cursor = a;
    while (b < cursor) {
        cursor = Math.floor((cursor - 1) / exports.ENUM_SIZE);
    }
    return b === cursor;
}
exports.aDescendsB = aDescendsB;
var PointCategory;
(function (PointCategory) {
    PointCategory[PointCategory["ANY"] = 0] = "ANY";
    PointCategory[PointCategory["AMENITY"] = 1] = "AMENITY";
    PointCategory[PointCategory["AMENITY_CAMP"] = 65] = "AMENITY_CAMP";
    PointCategory[PointCategory["AMENITY_CAMP_PITCH"] = 4161] = "AMENITY_CAMP_PITCH";
    PointCategory[PointCategory["AMENITY_CAMP_SITE"] = 4162] = "AMENITY_CAMP_SITE";
    PointCategory[PointCategory["AMENITY_FIRE"] = 66] = "AMENITY_FIRE";
    PointCategory[PointCategory["AMENITY_FIRE_BARBECUE"] = 4225] = "AMENITY_FIRE_BARBECUE";
    PointCategory[PointCategory["AMENITY_FIRE_PIT"] = 4226] = "AMENITY_FIRE_PIT";
    PointCategory[PointCategory["AMENITY_HUT"] = 67] = "AMENITY_HUT";
    PointCategory[PointCategory["AMENITY_HUT_ALPINE"] = 4289] = "AMENITY_HUT_ALPINE";
    PointCategory[PointCategory["AMENITY_HUT_WILDERNESS"] = 4290] = "AMENITY_HUT_WILDERNESS";
    PointCategory[PointCategory["AMENITY_PARKING"] = 68] = "AMENITY_PARKING";
    PointCategory[PointCategory["AMENITY_PICNIC"] = 69] = "AMENITY_PICNIC";
    PointCategory[PointCategory["AMENITY_PICNIC_SITE"] = 4417] = "AMENITY_PICNIC_SITE";
    PointCategory[PointCategory["AMENITY_PICNIC_TABLE"] = 4418] = "AMENITY_PICNIC_TABLE";
    PointCategory[PointCategory["AMENITY_SHELTER"] = 70] = "AMENITY_SHELTER";
    PointCategory[PointCategory["AMENITY_TOILETS"] = 71] = "AMENITY_TOILETS";
    PointCategory[PointCategory["AMENITY_WATER"] = 72] = "AMENITY_WATER";
    PointCategory[PointCategory["AMENITY_WATER_DRINKING"] = 4609] = "AMENITY_WATER_DRINKING";
    PointCategory[PointCategory["INFORMATION"] = 2] = "INFORMATION";
    PointCategory[PointCategory["INFORMATION_GUIDE_POST"] = 129] = "INFORMATION_GUIDE_POST";
    PointCategory[PointCategory["INFORMATION_VISITOR_CENTER"] = 130] = "INFORMATION_VISITOR_CENTER";
    PointCategory[PointCategory["NATURAL"] = 3] = "NATURAL";
    PointCategory[PointCategory["NATURAL_CAVE_ENTRANCE"] = 193] = "NATURAL_CAVE_ENTRANCE";
    PointCategory[PointCategory["NATURAL_PEAK"] = 194] = "NATURAL_PEAK";
    PointCategory[PointCategory["NATURAL_SADDLE"] = 195] = "NATURAL_SADDLE";
    PointCategory[PointCategory["NATURAL_VOLCANO"] = 196] = "NATURAL_VOLCANO";
    PointCategory[PointCategory["NATURAL_WATERFALL"] = 197] = "NATURAL_WATERFALL";
    PointCategory[PointCategory["WAY"] = 4] = "WAY";
    PointCategory[PointCategory["WAY_MOUNTAIN_PASS"] = 257] = "WAY_MOUNTAIN_PASS";
    PointCategory[PointCategory["WAY_PATH"] = 258] = "WAY_PATH";
    PointCategory[PointCategory["WAY_PATH_TRAILHEAD"] = 16513] = "WAY_PATH_TRAILHEAD";
    PointCategory[PointCategory["WAY_VIEWPOINT"] = 259] = "WAY_VIEWPOINT";
})(PointCategory || (exports.PointCategory = PointCategory = {}));
var RelationCategory;
(function (RelationCategory) {
    RelationCategory[RelationCategory["ANY"] = 0] = "ANY";
    RelationCategory[RelationCategory["BOUNDARY"] = 1] = "BOUNDARY";
    RelationCategory[RelationCategory["BOUNDARY_ABORIGINAL_LANDS"] = 65] = "BOUNDARY_ABORIGINAL_LANDS";
    RelationCategory[RelationCategory["BOUNDARY_ADMINISTRATIVE"] = 66] = "BOUNDARY_ADMINISTRATIVE";
    RelationCategory[RelationCategory["BOUNDARY_ADMINISTRATIVE_1"] = 4225] = "BOUNDARY_ADMINISTRATIVE_1";
    RelationCategory[RelationCategory["BOUNDARY_ADMINISTRATIVE_2"] = 4226] = "BOUNDARY_ADMINISTRATIVE_2";
    RelationCategory[RelationCategory["BOUNDARY_ADMINISTRATIVE_3"] = 4227] = "BOUNDARY_ADMINISTRATIVE_3";
    RelationCategory[RelationCategory["BOUNDARY_ADMINISTRATIVE_4"] = 4228] = "BOUNDARY_ADMINISTRATIVE_4";
    RelationCategory[RelationCategory["BOUNDARY_ADMINISTRATIVE_5"] = 4229] = "BOUNDARY_ADMINISTRATIVE_5";
    RelationCategory[RelationCategory["BOUNDARY_ADMINISTRATIVE_6"] = 4230] = "BOUNDARY_ADMINISTRATIVE_6";
    RelationCategory[RelationCategory["BOUNDARY_ADMINISTRATIVE_7"] = 4231] = "BOUNDARY_ADMINISTRATIVE_7";
    RelationCategory[RelationCategory["BOUNDARY_ADMINISTRATIVE_8"] = 4232] = "BOUNDARY_ADMINISTRATIVE_8";
    RelationCategory[RelationCategory["BOUNDARY_ADMINISTRATIVE_9"] = 4233] = "BOUNDARY_ADMINISTRATIVE_9";
    RelationCategory[RelationCategory["BOUNDARY_ADMINISTRATIVE_10"] = 4234] = "BOUNDARY_ADMINISTRATIVE_10";
    RelationCategory[RelationCategory["BOUNDARY_ADMINISTRATIVE_11"] = 4235] = "BOUNDARY_ADMINISTRATIVE_11";
    RelationCategory[RelationCategory["BOUNDARY_FOREST"] = 67] = "BOUNDARY_FOREST";
    RelationCategory[RelationCategory["BOUNDARY_NATIONAL_PARK"] = 68] = "BOUNDARY_NATIONAL_PARK";
    RelationCategory[RelationCategory["BOUNDARY_PROTECTED_AREA"] = 69] = "BOUNDARY_PROTECTED_AREA";
    RelationCategory[RelationCategory["BOUNDARY_PROTECTED_AREA_1A"] = 4417] = "BOUNDARY_PROTECTED_AREA_1A";
    RelationCategory[RelationCategory["BOUNDARY_PROTECTED_AREA_1B"] = 4418] = "BOUNDARY_PROTECTED_AREA_1B";
    RelationCategory[RelationCategory["BOUNDARY_PROTECTED_AREA_2"] = 4419] = "BOUNDARY_PROTECTED_AREA_2";
    RelationCategory[RelationCategory["BOUNDARY_PROTECTED_AREA_3"] = 4420] = "BOUNDARY_PROTECTED_AREA_3";
    RelationCategory[RelationCategory["BOUNDARY_PROTECTED_AREA_4"] = 4421] = "BOUNDARY_PROTECTED_AREA_4";
    RelationCategory[RelationCategory["BOUNDARY_PROTECTED_AREA_5"] = 4422] = "BOUNDARY_PROTECTED_AREA_5";
    RelationCategory[RelationCategory["BOUNDARY_PROTECTED_AREA_6"] = 4423] = "BOUNDARY_PROTECTED_AREA_6";
    RelationCategory[RelationCategory["ROUTE"] = 2] = "ROUTE";
    RelationCategory[RelationCategory["TRANSPORT"] = 129] = "TRANSPORT";
    RelationCategory[RelationCategory["TRANSPORT_BUS"] = 8257] = "TRANSPORT_BUS";
    RelationCategory[RelationCategory["TRANSPORT_DETOUR"] = 8258] = "TRANSPORT_DETOUR";
    RelationCategory[RelationCategory["TRANSPORT_FERRY"] = 8259] = "TRANSPORT_FERRY";
    RelationCategory[RelationCategory["TRANSPORT_LIGHT_RAIL"] = 8260] = "TRANSPORT_LIGHT_RAIL";
    RelationCategory[RelationCategory["TRANSPORT_RAILWAY"] = 8261] = "TRANSPORT_RAILWAY";
    RelationCategory[RelationCategory["TRANSPORT_ROAD"] = 8262] = "TRANSPORT_ROAD";
    RelationCategory[RelationCategory["TRANSPORT_SUBWAY"] = 8263] = "TRANSPORT_SUBWAY";
    RelationCategory[RelationCategory["TRANSPORT_TRAIN"] = 8264] = "TRANSPORT_TRAIN";
    RelationCategory[RelationCategory["TRANSPORT_TRACKS"] = 8265] = "TRANSPORT_TRACKS";
    RelationCategory[RelationCategory["TRANSPORT_TRAM"] = 8266] = "TRANSPORT_TRAM";
    RelationCategory[RelationCategory["TRANSPORT_TROLLEYBUS"] = 8267] = "TRANSPORT_TROLLEYBUS";
    RelationCategory[RelationCategory["TRAIL"] = 130] = "TRAIL";
    RelationCategory[RelationCategory["TRAIL_BICYCLE"] = 8321] = "TRAIL_BICYCLE";
    RelationCategory[RelationCategory["TRAIL_CANOE"] = 8322] = "TRAIL_CANOE";
    RelationCategory[RelationCategory["TRAIL_FOOT"] = 8323] = "TRAIL_FOOT";
    RelationCategory[RelationCategory["TRAIL_HIKING"] = 8324] = "TRAIL_HIKING";
    RelationCategory[RelationCategory["TRAIL_HORSE"] = 8325] = "TRAIL_HORSE";
    RelationCategory[RelationCategory["TRAIL_INLINE_SKATES"] = 8326] = "TRAIL_INLINE_SKATES";
    RelationCategory[RelationCategory["TRAIL_MTB"] = 8327] = "TRAIL_MTB";
    RelationCategory[RelationCategory["TRAIL_PISTE"] = 8328] = "TRAIL_PISTE";
    RelationCategory[RelationCategory["TRAIL_RUNNING"] = 8329] = "TRAIL_RUNNING";
    RelationCategory[RelationCategory["TRAIL_SKIING"] = 8330] = "TRAIL_SKIING";
})(RelationCategory || (exports.RelationCategory = RelationCategory = {}));
var WayCategory;
(function (WayCategory) {
    WayCategory[WayCategory["ANY"] = 0] = "ANY";
    WayCategory[WayCategory["HIGHWAY"] = 1] = "HIGHWAY";
    WayCategory[WayCategory["RAIL"] = 65] = "RAIL";
    WayCategory[WayCategory["ROAD"] = 66] = "ROAD";
    WayCategory[WayCategory["ROAD_MOTORWAY"] = 4225] = "ROAD_MOTORWAY";
    WayCategory[WayCategory["ROAD_TRUNK"] = 4226] = "ROAD_TRUNK";
    WayCategory[WayCategory["ROAD_PRIMARY"] = 4227] = "ROAD_PRIMARY";
    WayCategory[WayCategory["ROAD_SECONDARY"] = 4228] = "ROAD_SECONDARY";
    WayCategory[WayCategory["ROAD_TERTIARY"] = 4229] = "ROAD_TERTIARY";
    WayCategory[WayCategory["ROAD_UNCLASSIFIED"] = 4230] = "ROAD_UNCLASSIFIED";
    WayCategory[WayCategory["ROAD_RESIDENTIAL"] = 4231] = "ROAD_RESIDENTIAL";
    WayCategory[WayCategory["ROAD_MOTORWAY_LINK"] = 4232] = "ROAD_MOTORWAY_LINK";
    WayCategory[WayCategory["ROAD_TRUNK_LINK"] = 4233] = "ROAD_TRUNK_LINK";
    WayCategory[WayCategory["ROAD_PRIMARY_LINK"] = 4234] = "ROAD_PRIMARY_LINK";
    WayCategory[WayCategory["ROAD_SECONDARY_LINK"] = 4235] = "ROAD_SECONDARY_LINK";
    WayCategory[WayCategory["ROAD_TERTIARY_LINK"] = 4236] = "ROAD_TERTIARY_LINK";
    WayCategory[WayCategory["ROAD_LIVING_STREET"] = 4237] = "ROAD_LIVING_STREET";
    WayCategory[WayCategory["ROAD_SERVICE"] = 4238] = "ROAD_SERVICE";
    WayCategory[WayCategory["ROAD_PEDESTRIAN"] = 4239] = "ROAD_PEDESTRIAN";
    WayCategory[WayCategory["ROAD_TRACK"] = 4240] = "ROAD_TRACK";
    WayCategory[WayCategory["ROAD_BUS_GUIDEWAY"] = 4241] = "ROAD_BUS_GUIDEWAY";
    WayCategory[WayCategory["ROAD_ESCAPE"] = 4242] = "ROAD_ESCAPE";
    WayCategory[WayCategory["ROAD_RACEWAY"] = 4243] = "ROAD_RACEWAY";
    WayCategory[WayCategory["ROAD_BUSWAY"] = 4244] = "ROAD_BUSWAY";
    WayCategory[WayCategory["PATH"] = 67] = "PATH";
    WayCategory[WayCategory["PATH_FOOTWAY"] = 4289] = "PATH_FOOTWAY";
    WayCategory[WayCategory["PATH_BRIDLEWAY"] = 4290] = "PATH_BRIDLEWAY";
    WayCategory[WayCategory["PATH_STEPS"] = 4291] = "PATH_STEPS";
    WayCategory[WayCategory["PATH_CORRIDOR"] = 4292] = "PATH_CORRIDOR";
    WayCategory[WayCategory["PATH_CYCLEWAY"] = 4293] = "PATH_CYCLEWAY";
    WayCategory[WayCategory["PISTE"] = 2] = "PISTE";
    WayCategory[WayCategory["PISTE_DOWNHILL"] = 129] = "PISTE_DOWNHILL";
    WayCategory[WayCategory["PISTE_NORDIC"] = 130] = "PISTE_NORDIC";
    WayCategory[WayCategory["PISTE_SKITOUR"] = 131] = "PISTE_SKITOUR";
    WayCategory[WayCategory["PISTE_SLED"] = 132] = "PISTE_SLED";
    WayCategory[WayCategory["PISTE_HIKE"] = 133] = "PISTE_HIKE";
    WayCategory[WayCategory["PISTE_SLEIGH"] = 134] = "PISTE_SLEIGH";
    WayCategory[WayCategory["PISTE_ICE_SKATE"] = 135] = "PISTE_ICE_SKATE";
    WayCategory[WayCategory["PISTE_SKI_JUMP"] = 136] = "PISTE_SKI_JUMP";
    WayCategory[WayCategory["PISTE_CONNECTION"] = 137] = "PISTE_CONNECTION";
    WayCategory[WayCategory["AERIALWAY"] = 3] = "AERIALWAY";
    WayCategory[WayCategory["AERIALWAY_CABLE_CAR"] = 193] = "AERIALWAY_CABLE_CAR";
    WayCategory[WayCategory["AERIALWAY_GONDOLA"] = 194] = "AERIALWAY_GONDOLA";
    WayCategory[WayCategory["AERIALWAY_MIXED_LIFT"] = 195] = "AERIALWAY_MIXED_LIFT";
    WayCategory[WayCategory["AERIALWAY_CHAIR_LIFT"] = 196] = "AERIALWAY_CHAIR_LIFT";
    WayCategory[WayCategory["AERIALWAY_DRAG_LIFT"] = 197] = "AERIALWAY_DRAG_LIFT";
    WayCategory[WayCategory["AERIALWAY_T_BAR"] = 198] = "AERIALWAY_T_BAR";
    WayCategory[WayCategory["AERIALWAY_J_BAR"] = 199] = "AERIALWAY_J_BAR";
    WayCategory[WayCategory["AERIALWAY_PLATTER"] = 200] = "AERIALWAY_PLATTER";
    WayCategory[WayCategory["AERIALWAY_ROPE_TOW"] = 201] = "AERIALWAY_ROPE_TOW";
    WayCategory[WayCategory["AERIALWAY_MAGIC_CARPET"] = 202] = "AERIALWAY_MAGIC_CARPET";
    WayCategory[WayCategory["AERIALWAY_ZIP_LINE"] = 203] = "AERIALWAY_ZIP_LINE";
    WayCategory[WayCategory["AERIALWAY_GOODS"] = 204] = "AERIALWAY_GOODS";
    WayCategory[WayCategory["WATERWAY"] = 4] = "WATERWAY";
    WayCategory[WayCategory["WATERWAY_FERRY"] = 257] = "WATERWAY_FERRY";
})(WayCategory || (exports.WayCategory = WayCategory = {}));
